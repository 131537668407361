import { useEffect, useRef, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TotalSupply from './components/TotalSupply';
import Home from './components/Home';
import NotFound from './components/NotFound';



function App() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMblNav, setIsMblNav] = useState(false)

    const toggleNavbar = () => setIsMblNav(!isMblNav)
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [activeSection, setActiveSection] = useState('overview');

    const handleSetActive = (section) => {
        if (section) {
            setActiveSection(section?.id);
        }
    };
    console.log(activeSection, "current")
    return (
        <>
            <Router>
                <div>
                    <Routes>
                    <Route path="/" element={<Home />} />

                        <Route path="/api/supply/total" element={<TotalSupply />} />
                        <Route path="/api/supply/circulating" element={<TotalSupply />} />
                        <Route path="*" element={<NotFound />} /> 

                    </Routes>
                </div>
            </Router>
            
        </>
    );
}

export default App;
