import { useEffect, useState } from 'react';
import { desktopImages, laptopImages, mobileImages } from '../utils/images';
import Scrollspy from 'react-scrollspy';



function Home() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMblNav, setIsMblNav] = useState(false)

    const toggleNavbar = () => setIsMblNav(!isMblNav)
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [activeSection, setActiveSection] = useState('overview');

    const handleSetActive = (section) => {
        if (section) {
            setActiveSection(section?.id);
        }
    };
    console.log(activeSection, "current")
    return (
        <>
            <Scrollspy
                items={['overview', 'featuressection', 'tokonomicssection', "buysection"]}
                currentClassName="active"
                offset={-300}
                onUpdate={handleSetActive}
            >

                <div className="App">
                    {screenWidth <= 768 ?
                        <div className={`mobile-header ${isMblNav ? "active" : "unactive"}`}>
                            <div className={`parent-container`}>
                                <div className='container'>
                                    <div className="logo-container">
                                        <div>
                                            <img src={mobileImages?.mblMainLogo} alt='logo' />
                                        </div>
                                        <div className="logo-text">
                                            CutieCoon</div>
                                    </div>
                                    <div><img onClick={toggleNavbar} src={mobileImages?.crossIcon} /></div>
                                </div>

                                <div className="list-items-container">
                                    <ul>
                                        <li><a href="#overview">Overview
                                            <div className={`navbar-active ${activeSection === "overview" ? "active" : ""}`}></div>
                                        </a>
                                        </li>
                                        <li><a href="#featuressection">Features
                                            <div className={`navbar-active ${activeSection === "featuressection" ? "active" : ""}`}></div>

                                        </a></li>
                                        <li><a href="#tokonomicssection">Tokenomics
                                            <div className={`navbar-active ${activeSection === "tokonomicssection" ? "active" : ""}`}></div>

                                        </a></li>
                                        <li><a href="#buysection">How to Buy
                                            <div className={`navbar-active ${activeSection === "buysection" ? "active" : ""}`}></div>

                                        </a></li>
                                        <li><a href="https://cutiecoon-whitepaper.gitbook.io/whitepaper-or-cutiecoon" target='_blank'>Whitepaper
                                            <div className={`navbar-active`}></div>

                                        </a></li>

                                    </ul>
                                    <a href='https://jup.ag/swap/USDT-ACTfhg3YSxQpRdE2nwKcutxhS1KfyRUkajxbGUHcTPiP' target='_blank' className="cutie-coon-button">Buy $Qtcoon</a>
                                </div>
                            </div>

                        </div> : null}

                    <header id="overview">
                        <nav style={{ zIndex: isMblNav && 999 }}>
                            <div className="container">
                                <div className="logo-container">
                                    <div>
                                        <img src={screenWidth <= 768 ? mobileImages?.mblMainLogo : screenWidth <= 1919 ? laptopImages?.lpMainLogo : desktopImages?.mainLogo} alt='logo' />
                                    </div>
                                    <div className="logo-text">CutieCoon</div>
                                </div>
                                {screenWidth <= 767 ? <div><img onClick={toggleNavbar} src={mobileImages?.navbarToggleIcon} /></div> : null}
                                <div className="list-items-container">
                                    <ul>
                                        <li><a href="#overview">Overview
                                            <div className={`navbar-active ${activeSection === "overview" ? "active" : ""}`}></div>
                                        </a>
                                        </li>
                                        <li><a href="#featuressection">Features
                                            <div className={`navbar-active ${activeSection === "featuressection" ? "active" : ""}`}></div>

                                        </a></li>
                                        <li><a href="#tokonomicssection">Tokenomics
                                            <div className={`navbar-active ${activeSection === "tokonomicssection" ? "active" : ""}`}></div>

                                        </a></li>
                                        <li><a href="#buysection">How to Buy
                                            <div className={`navbar-active ${activeSection === "buysection" ? "active" : ""}`}></div>

                                        </a></li>
                                        <li><a href="https://cutiecoon-whitepaper.gitbook.io/whitepaper-or-cutiecoon" target='_blank'>Whitepaper
                                            <div className={`navbar-active`}></div>

                                        </a></li>
                                    </ul>
                                    <a href='https://jup.ag/swap/USDT-ACTfhg3YSxQpRdE2nwKcutxhS1KfyRUkajxbGUHcTPiP' target='_blank' className="cutie-coon-button">Buy $Qtcoon</a>
                                </div>

                            </div>
                        </nav>
                        <div className='invincible-div'></div>

                        <div className="header-hero-section">
                            <div className="floating-img-one">
                                <img src={desktopImages?.headerImgOne} />
                            </div>
                            <div className="text-content">
                                <div className='animated-border-text'>100% Community Owned
                                </div>
                                <div className="heading">The Next Big Meme Coin <br /> Who Can Resist the Cuteness of a Baby Raccoon???</div>
                                <div className="paragraph">Embrace the adorable revolution with our token featuring fast transactions, low fees, and a community-driven ecosystem. Join forces with CutieCoon and enjoy secure, decentralized transactions while supporting the cuteness!</div>
                                <a href='https://jup.ag/swap/USDT-ACTfhg3YSxQpRdE2nwKcutxhS1KfyRUkajxbGUHcTPiP' target='_blank' className="cutie-coon-button">Buy $Qtcoon</a>
                            </div>
                            <div className="floating-img-two">
                                <img src={desktopImages?.headerImgTwo} />

                            </div>
                        </div>
                    </header>

                    <section id='featuressection' className="coins-feature-container">
                        <div className="text-container">
                            <div className="heading">Coin Features</div>
                            <div className="paragraph">Cutiecoon Coin features CutieSwap Exchange and a community-driven ecosystem.</div>
                        </div>
                        <div className="items-layout-container">
                            <div className="cards">
                                <div className="card-item">
                                    <div className="card-img">
                                        <img src={screenWidth <= 768 ? mobileImages?.mblCardImgOne : desktopImages?.cardImgOne} />
                                    </div>
                                    <div className="card-text-container">
                                        <div className="card-heading">Revolutionizing DeFi on Solana <br />  Blockchain </div>
                                        <div className="card-paragraph">Explore the forefront of decentralized finance (DeFi) with CutieCoin, leveraging the lightning-fast Solana blockchain for unparalleled efficiency. Dive into a world of low-cost transactions and high-speed trading, empowering users to engage with their favorite meme coins.</div>
                                    </div>
                                </div>
                                <div className="card-item">
                                    <div className="card-img"><img src={screenWidth <= 768 ? mobileImages?.mblCardImgTwo : desktopImages?.cardImgTwo} /></div>
                                    <div className="card-text-container">

                                        <div className="card-heading">Sustainable Crypto <br /> Economy</div>

                                        <div className="card-paragraph">To ensure the rewards remain irresistibly attractive to both existing and new buyers in the long run, we've implemented a groundbreaking solution to maintain a sustainable and flourishing coin economy.</div>
                                    </div>
                                </div>

                            </div>
                            <div className="cartoon">
                                <img src={screenWidth <= 1919 ? laptopImages?.lpCardsCartoon : desktopImages?.cardsCartoon} /></div>

                            <div className="cards">
                                <div className="card-item">
                                    <div className="card-img">
                                        <img src={screenWidth <= 768 ? mobileImages?.mblCardImgThree : desktopImages?.cardImgThree} />
                                    </div>
                                    <div className="card-text-container">
                                        <div className="card-heading">Community-driven <br /> Development </div>
                                        <div className="card-paragraph">Through decentralized governance, holders of QTcoon have the power to vote on important decisions regarding the future development and direction of the project.</div>
                                    </div>
                                </div>
                                <div className="card-item">
                                    <div className="card-img"><img src={screenWidth <= 768 ? mobileImages?.mblCardImgFour : desktopImages?.cardImgFour} /></div>
                                    <div className="card-text-container">

                                        <div className="card-heading">Real WorldToken Utility <br />
                                            (Coming Soon)</div>

                                        <div className="card-paragraph">Users who buy QTcoon do not have to convert into fiat currency to use in the real world. We provide special privileges and benefits when they pay in QTcoon.</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>
                    <div className="bg-curves-one">
                        <div className='bg-overlay-one'>
                            <img src={screenWidth <= 768 ? mobileImages?.mblBgCurveOne : desktopImages?.bgCurveOne} />
                        </div>

                        <section className="tokeomics-container" id="tokonomicssection">
                            <div className="text-container">
                                <div className="heading">Tokenomics</div>
                                <div className="paragraph">Your gateway to a decentralized economy, offering secure transactions, robust tokenomics, and community empowerment.</div>

                            </div>

                            <div className='tokenomics-image-section-container'>
                            <img src={desktopImages?.tokenMainImg} />

                            </div>
                        </section>

                        <section className="buy-container" id='buysection'>
                            <div className="text-container">
                                <div className="heading">How to Buy</div>
                                <div className="paragraph">Simple steps to join the fun: Create wallet, deposit Solana/USDT, swap for QTcoon, and dive into the world of cuteness</div>

                            </div>
                            <div className="items-container">
                                <div className="list-items-container">
                                    <div className="item">
                                        <div className="numbering">1</div>
                                        <div className="line"><img src={screenWidth <= 768 ? mobileImages?.mblLineImg : desktopImages?.lineImg} /></div>
                                        <div className="text-container">
                                            <div className="heading">Create Your Phantom Wallet on Jupiter (jup.ag)</div>
                                            <div className="paragraph">Join the Solana ecosystem effortlessly by setting up your Phantom Wallet on Jupiter, your gateway to seamless crypto transactions.</div>

                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="numbering">2</div>
                                        <div className="line"><img src={screenWidth <= 768 ? mobileImages?.mblLineImg : desktopImages?.lineImg} /></div>
                                        <div className="text-container">
                                            <div className="heading">Deposit Solana or USDT into Your Wallet</div>
                                            <div className="paragraph">Power up your wallet with Solana or USDT to unlock a world of possibilities in the CutieCoin ecosystem.</div>

                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="numbering">3</div>
                                        <div className="line"><img src={screenWidth <= 768 ? mobileImages?.mblLineImg : desktopImages?.lineImg} /></div>
                                        <div className="text-container">
                                            <div className="heading">Connect Your Wallet to Swap QTcoon</div>
                                            <div className="paragraph">Seamlessly integrate your Phantom Wallet with our platform to initiate your journey into the realm of CutieCoin.</div>

                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="numbering">4</div>
                                        <div className="line"><img src={screenWidth <= 768 ? mobileImages?.mblLineImg : desktopImages?.lineImg} /></div>
                                        <div className="text-container">
                                            <div className="heading">Swap for QTcoon (Select All From Token List)</div>
                                            <div className="paragraph">Dive into the excitement as you swap your deposited assets for QTcoon tokens, ready to embark on your memetic adventure!</div>

                                        </div>
                                    </div>

                                </div>
                                <div className="img">
                                    <img src={screenWidth <= 768 ? mobileImages?.mblBuyBigCutie : screenWidth < 1920 ? laptopImages?.lpBuyBigCutie : desktopImages?.buyBigCutie} />
                                </div>

                            </div>

                        </section>
                    </div>
                    <div className="bg-curves-two">
                        <div className='bg-overlay-two'>
                            <img src={screenWidth <= 768 ? mobileImages?.mblBgCurveTwo : desktopImages?.bgCurveTwo} />
                        </div>


                        <section className="buddies-nfts-container">
                            <div className="text-container">
                                <div className="heading">Buddies & NFT (Coming Soon)</div>
                                <div className="paragraph">Uniting digital art and friendship, Buddies offers unique NFTs featuring adorable characters. Get ready to collect, trade, and connect with your virtual pals on the blockchain. Stay tuned for updates!</div>
                            </div>
                            <div className="items-container">
                                <div className="item">
                                    <div className="img" >
                                        <img src={screenWidth <= 768 ? mobileImages?.mblBuddies : desktopImages?.buddies} />
                                    </div>
                                    <div className="nfts-btn-container">

                                        <div className="text-container">
                                            <div className="heading">Buddies</div>
                                            <div className="paragraph">Every CutieCoon Buddy is a Non-Fungible Token (NFT) possessing unique attributes and properties. With time, their attributes can evolve, while their properties bestow advantages or disadvantages in comparison to other Buddies. Engage your Buddies in quests, raids, PVP battles, user-created activities, and assign them to tasks. Collect them as your digital family, each one a distinct personality awaiting your adventure!</div>

                                        </div>
                                        {/* <a href='#' className="button">View on OpenSea</a> */}

                                    </div>
                                </div>
                                <div className="item">
                                    <div className="img">
                                        <img src={screenWidth <= 768 ? mobileImages?.mblNftLand : desktopImages?.nftLand} />
                                    </div>
                                    <div className="nfts-btn-container">
                                        <div className="text-container">
                                            <div className="heading">CutieCoon World Land</div>
                                            <div className="paragraph">Each parcel of CutieCoon Land represents a distinct  NFT facilitating trade and purchase interactions among users. As proud owners, individuals wield absolute authority over land development, fostering revenue streams, erecting architectural marvels, leasing to fellow players, and orchestrating captivating campaigns, events, and interactive activities. Immerse yourself in the dynamic world of CutieCoon Lands.</div>

                                        </div>
                                        {/* <a href='#' className="button">View in Marketplace</a> */}
                                    </div>
                                </div>
                            </div>

                        </section>

                        <section className="community-container">
                            <div className="text-container">
                                <div className="heading">Contract Address</div>
                                <div className="paragraph">Swap directly through our contract address for seamless and secure transactions.</div>

                            </div>
                            <div className="token-box-container">
                                <div className="token-box">
                                    <div className="token-text">ACTfhg3YSxQpRdE2nwKcutxhS1KfyRUkajxbGUHcTPiP</div>
                                    <a href='https://jup.ag/swap/USDT-ACTfhg3YSxQpRdE2nwKcutxhS1KfyRUkajxbGUHcTPiP' target='_blank' className="button">Swap Cutiecoon</a>
                                </div>
                            </div>

                        </section>
                    </div>

                    <footer>
                        <section className="social-links-container">

                            <a href='https://x.com/cutiecoon?s=21' target='_blank'>
                                <img src={screenWidth <= 768 ? mobileImages?.mblTwitterIcon : desktopImages?.twitterIcon} alt='twi' />
                            </a>
                            <a href='https://t.me/cutiecoon' target='_blank'>
                                <img src={screenWidth <= 768 ? mobileImages?.mblSendIcon : desktopImages?.sendIcon} alt='sendIco' />
                            </a>
                            <a>
                                <img src={screenWidth <= 768 ? mobileImages?.mblInstaIcon : desktopImages?.instaIcon} alt='linked' />
                            </a>
                            <a>
                                <img src={screenWidth <= 768 ? mobileImages?.mblMIcon : desktopImages?.mIcon} alt='mIcon' />
                            </a>


                            <a>
                                <img src={screenWidth <= 768 ? mobileImages?.mblRedditIcon : desktopImages?.redditIcon} alt='red' />
                            </a>
                            <a>
                                <img src={screenWidth <= 768 ? mobileImages?.mblDiscordIcon : desktopImages?.discordIcon} alt='discord' />
                            </a>
                            <a>
                                <img src={screenWidth <= 768 ? mobileImages?.mblMailIcon : desktopImages?.youtubeIcon} alt='youtubeicon' />
                            </a>

                        </section>

                        {/* <section className="marketing">
                    <div className="email-container">
                        <div className="email">
                            <img src={screenWidth <= 768 ? mobileImages?.mblMailIcon : desktopImages?.mailIcon} alt='mailicon' />

                        </div>
                        <div className="email-text">enquiries@cuitecoon.com</div>
                    </div>
                    <div className="email-container">
                        <div className="email">
                            <img src={screenWidth <= 768 ? mobileImages?.mblMailIcon : desktopImages?.mailIcon} alt='mailicon' />

                        </div>
                        <div className="email-text">marketing@cuitecoon.com</div>
                    </div>
                    <div className="email-container">
                        <div className="email">
                            <img src={screenWidth <= 768 ? mobileImages?.mblMailIcon : desktopImages?.mailIcon} alt='mailicon' />

                        </div>
                        <div className="email-text">partnerships@cutiecoon.com</div>
                    </div>
                </section> */}

                        <section className="nav-list">
                            <div className="moon-img"><img src={screenWidth <= 768 ? mobileImages?.mblMoonFooter : desktopImages?.moonFooter} /></div>

                            <div className="sun-img"><img src={screenWidth <= 768 ? mobileImages?.mblSunFooter : desktopImages?.sunFooter} /></div>
                            {screenWidth <= 768 ?
                                <ul>
                                    {/* featuressection/tokonomicssection/buysection */}
                                    <div className='li-items'>
                                        <li><a href="#featuressection">Features</a></li>
                                        <li><a href="#tokonomicssection">Tokenomics</a></li>
                                        <li><a href="#buysection">How to buy</a></li>
                                    </div>
                                    <div className='li-items'>
                                        <li><a href="https://cutiecoon-whitepaper.gitbook.io/whitepaper-or-cutiecoon" target='_blank'>Whitepaper

                                        </a></li>
                                        <li><a href="#">TERMS & CONDITION</a></li>
                                        <li><a href="#">privacy policy</a></li>
                                    </div>
                                </ul>
                                : <ul>
                                    <li><a href="#featuressection">Features</a></li>
                                    <li><a href="#tokonomicssection">Tokenomics</a></li>
                                    <li><a href="#buysection">How to buy</a></li>
                                    <li><a href="https://cutiecoon-whitepaper.gitbook.io/whitepaper-or-cutiecoon" target='_blank'>Whitepaper

                                    </a></li>
                                    <li><a href="#">TERMS & CONDITION</a></li>
                                    <li><a href="#">privacy policy</a></li>
                                </ul>}

                        </section>
                        <section className='copy-right-container'>
                            Copyright © 2024 CutieCoon, all rights reserved
                        </section>
                    </footer>
                </div>
            </Scrollspy>
        </>
    );
}

export default Home;
