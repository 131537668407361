import React, { useEffect, useLayoutEffect } from 'react'

const TotalSupply = () => {
    useEffect(() => {
        document.body.style.background = 'white';
    
        // Cleanup function to reset the background color when the component unmounts
        return () => {
          document.body.style.background = '';
        };
      }, []);
  return <div className='api-texts'>999944975.630431</div>
}

export default TotalSupply