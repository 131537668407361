import mainLogo from "../public/images/main-logo-coin.svg"
import buddies from "../public/images/buddies.svg"
import headerImgTwo from "../public/images/header-img-two.svg"
import headerImgOne from "../public/images/header-img-one.svg"
import cardImgOne from "../public/images/card-img-one.svg"
import cardImgTwo from "../public/images/card-img-two.svg"
import cardsCartoon from "../public/images/cards-cartoon.svg"
import cardImgThree from "../public/images/card-img-three.svg"
import cardImgFour from "../public/images/card-img-four.svg"
import tokenImgOne from "../public/images/token-image-one.svg"
import tokenImgTwo from "../public/images/token-image-two.svg"
import tokenImgThree from "../public/images/token-img-three.svg"
import mainTokenImg from "../public/images/main-image-token.svg"
import lineImg from "../public/images/line.svg"
import buyBigCutie from "../public/images/buy-big-cutie.svg"
import nftLand from "../public/images/nft-land.svg"
import moonFooter from "../public/images/nightMoonIcon.svg"
import sunFooter from "../public/images/moonIcon.svg"
import sendIcon from "../public/images/sendIcon.svg"
import linkedIcon from "../public/images/LinkedInIcon.svg"
import mIcon from "../public/images/mIcon.svg"
import twitterIcon from "../public/images/twitterIcon.svg"
import discordIcon from "../public/images/discordIcon.svg"
import youtubeIcon from "../public/images/youtube.svg"
import redditIcon from "../public/images/redditIcon.svg"
import mailIcon from "../public/images/mailIcon.svg"
import bgCurveOne from "../public/images/bg-curve-one.png"
import bgCurveTwo from "../public/images/bg-curve-two.png"
import instaIcon from "../public/images/instaIcon.svg"
import tokenMainImg from "../public/images/tokenomic-img-main.png"

// laptop images
import lpMainLogo from "../public/images/laptopscreen/main-logo-coin.svg"
import lpHeaderImgOne from "../public/images/laptopscreen/header-img-one.svg"
import lpHeaderImgTwo from "../public/images/laptopscreen/header-img-two.svg"
import lpCardsCartoon from "../public/images/laptopscreen/cardsCartoon.svg"
import lpMainTokenImg from "../public/images/laptopscreen/main-token-img.svg"
import lpTokenImgOne from "../public/images/laptopscreen/token-img-one.svg"
import lpTokenImgTwo from "../public/images/laptopscreen/token-img-two.svg"
import lpTokenImgThree from "../public/images/laptopscreen/token-img-three.svg"
import lpBuyBigCutie from "../public/images/laptopscreen/buy-big-cutie.svg"

// mobile images
import mblMainLogo from "../public/images/mobilescreen/main-logo-coin.svg"
import mblHeaderImgOne from "../public/images/mobilescreen/header-img-one.svg"
import mblHeaderImgTwo from "../public/images/mobilescreen/header-img-two.svg"
import mblCardImgOne from "../public/images/mobilescreen/card-img-one.svg"
import mblCardImgTwo from "../public/images/mobilescreen/card-img-two.svg"
import mblCardImgThree from "../public/images/mobilescreen/card-img-three.svg"
import mblCardImgFour from "../public/images/mobilescreen/card-img-four.svg"
import mblMoonFooter from "../public/images/mobilescreen/nightMoonIcon.svg"
import mblSunFooter from "../public/images/mobilescreen/moonIcon.svg"
import mblSendIcon from "../public/images/mobilescreen/sendIcon.svg"
import mblTwitterIcon from "../public/images/mobilescreen/twitterIcon.svg"
import mblMIcon from "../public/images/mobilescreen/mIcon.svg"
import mblRedditIcon from "../public/images/mobilescreen/redditIcon.svg"
import mblYoutubeIcon from "../public/images/mobilescreen/youtube.svg"
import mblDiscordIcon from "../public/images/mobilescreen/discordIcon.svg"
import mblMailIcon from "../public/images/mobilescreen/mailIcon.svg"
import mblNftLand from "../public/images/mobilescreen/nft-land.svg"
import mblBuddies from "../public/images/mobilescreen/buddies.svg"
import mblBuyBigCutie from "../public/images/mobilescreen/buy-big-cutie.svg"
import mblMainTokenImg from "../public/images/mobilescreen/main-token-image.svg"
import mblTokenImgTwo from "../public/images/mobilescreen/token-image-two.svg"
import navbarToggleIcon from "../public/images/mobilescreen/navbarToggleIcon.svg"
import crossIcon from "../public/images/mobilescreen/cross-icon.svg"
import mblLineImg from "../public/images/mobilescreen/line.svg"
import mblBgCurveOne from "../public/images/mobilescreen/bg-curve-one.png"
import mblBgCurveTwo from "../public/images/mobilescreen/bg-curve-two.png"
import mblInstaIcon from "../public/images/mobilescreen/instaIcon.svg"


export const desktopImages = {
    mainLogo,
    buddies,
    headerImgTwo,
    headerImgOne,
    cardImgOne,
    cardImgTwo,
    cardsCartoon,
    cardImgThree,
    cardImgFour,
    tokenImgOne,
    tokenImgTwo,
    tokenImgThree,
    mainTokenImg,
    lineImg,
    buyBigCutie,
    nftLand,
    moonFooter,
    sunFooter,
    sendIcon,
    linkedIcon,
    mIcon,
    twitterIcon,
    discordIcon,
    youtubeIcon,
    redditIcon,
    mailIcon,
    bgCurveOne,
    bgCurveTwo,
    instaIcon,
    tokenMainImg

}

export const laptopImages = {
    lpMainLogo,
    lpHeaderImgOne,
    lpHeaderImgTwo,
    lpCardsCartoon,
    lpMainTokenImg,
    lpTokenImgOne,
    lpTokenImgTwo,
    lpTokenImgThree,
    lpBuyBigCutie,
}


export const mobileImages = {
 mblMainLogo ,
 mblHeaderImgOne ,
 mblHeaderImgTwo ,
 mblCardImgOne,
 mblCardImgTwo ,
 mblCardImgThree ,
 mblCardImgFour,
 mblMoonFooter ,
 mblSunFooter ,
 mblSendIcon ,
 mblTwitterIcon ,
 mblMIcon ,
 mblRedditIcon ,
 mblYoutubeIcon ,
 mblDiscordIcon ,
 mblMailIcon ,
 mblNftLand ,
 mblBuddies ,
 mblBuyBigCutie ,
 mblMainTokenImg ,
 mblTokenImgTwo ,
 navbarToggleIcon ,
 crossIcon ,
 mblLineImg ,
 mblBgCurveOne ,
 mblBgCurveTwo ,
 mblInstaIcon
}